@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  color: #121B38;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='color'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border-radius: 5px;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-color-swatch {
  border: 0;
  border-radius: 5px;
}

::-moz-color-swatch,
::-moz-focus-inner {
  border: 0;
}

::-moz-focus-inner {
  padding: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: 'Inter', sans-serif;
}

input:-webkit-autofill {
  background-color: white;
}
input:autofill {
  background-color: white;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[vaul-drawer]::after {
  content: none !important;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: rgba(0, 0, 0, 0.12);
    --muted-foreground: rgba(0, 0, 0, 0.26);

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 0, 0%, 89%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: #2f72ff;
    --primary-foreground: 210 40% 98%;

    --secondary: 0, 0%, 100%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 5, 81%, 56%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --label: 227, 13%, 40%;
    --button-hover: 0, 0%, 95%;

    --backdrop-dialog: rgba(0, 0, 0, 0.41);
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 0, 0%, 89%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 0, 0%, 100%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: 'rlig' 1, 'calt' 1;
  }
}

input[type='datetime-local'] {
  padding-right: 12px;
}

input[type='datetime-local']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
  margin-left: -8px;
}

@media (max-width: 640px) {
  .rdrDateRangePickerWrapper {
    display: block !important;
  }
  .rdrDateDisplayWrapper {
    padding: 1px 0;
  }
  .rdrDateRangeWrapper {
    display: block !important;
  }
  .rdrDefinedRangesWrapper {
    display: none !important;
  }
  .rdrMonths {
    flex-direction: column;
  }
  .rdrMonth {
    width: 100% !important;
  }
}

.required-field:after {
  color: #cb2711;
  content: '*';
  padding: 2px;
}

.slide-up-enter {
  transform: translateY(100%);
  opacity: 0;
}
.slide-up-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 150ms, opacity 150ms;
}
.slide-up-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-up-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 150ms, opacity 150ms;
}

/* Slide down transition */
.slide-down-enter {
  transform: translateY(-100%);
  opacity: 0;
}
.slide-down-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: transform 150ms, opacity 150ms;
}
.slide-down-exit {
  transform: translateY(0);
  opacity: 1;
}
.slide-down-exit-active {
  transform: translateY(100%);
  opacity: 0;
  transition: transform 150ms, opacity 150ms;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  position: relative;
}

.loader .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 8.57143px solid rgb(230, 230, 230);
  opacity: 1;
  position: absolute;
}

.loader .logo {
  position: absolute;
  height: 48px;
  width: 48px;
}

.loader .spin {
  display: inherit;
  position: relative;
  width: 100%;
  height: 100%;
  animation: loader-spin 0.666667s linear infinite;
}

.loader .dot {
  width: 8.57143px;
  height: 8.57143px;
  border-radius: 50%;
  background-color: rgb(204, 204, 204);
  opacity: 0.9;
  position: absolute;
  top: 30.7143px;
  animation: loader-spin 0.666667s linear infinite;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes scroll-snap {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

@keyframes marquee {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}

.animate-scroll-snap {
  animation: scroll-snap 3s linear infinite;
}

/*.apexcharts-legend{*/
/*  top: 10px !important;*/
/*  right: 10px !important;*/
/*}*/
.apexcharts-legend-series{
  display: flex !important;
  align-items: center !important;
}

